<template>
    <div class="footer-panel-wrap">
      <div class="content">
        <div class="split-panel button-group">
          <div class="left-block">
            <div class="button-wrap">
              <el-button type="text" @click="openDoc('service')">{{
                $t('footer.service')
              }}</el-button>
              <el-button type="text" @click="openDoc('privacy')">{{
                $t('footer.privacy')
              }}</el-button>
              <el-button type="text" @click="openMessage">{{
                $t('footer.contact')
              }}</el-button>
              <el-button type="text" @click="download">{{
                $t('footer.client')
              }}</el-button>
            </div>
          </div>
          <div class="right-block">
            <div class="icon-wrap">
              <i class="mofont mo-weixin" @click="weixin"></i>
              <i class="mofont mo-zhihu" @click="zhihu"></i>
              <i class="mofont mo-bilibili" @click="bilibili"></i>
            </div>
          </div>
        </div>
        <div class="split-panel">
          <div class="left-block">
            <div class="info">
              <i style="margin-right: 2px">© </i> 2022 {{ $t('footer.company') }}
              {{ $t('footer.right') }}
              <div class="beian-wrap">
                <a
                  href="https://beian.miit.gov.cn/"
                  target="_blank"
                  class="link"
                  style="color: #939393"
                  >苏ICP备08107463号-4</a
                >
                <div style="width: 300px; padding: 5px 0">
                  <a
                    target="_blank"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002004101"
                    style="
                      display: inline-block;
                      text-decoration: none;
                      height: 20px;
                      line-height: 20px;
                    "
                    class="link"
                    ><img
                      src="../../assets/images/beian.png"
                      style="float: left"
                    />
                    <p
                      style="
                        float: left;
                        height: 20px;
                        line-height: 20px;
                        margin: 0px 0px 0px 5px;
                        color: #939393;
                      "
                    >
                      苏公网安备 32059002004101号
                    </p></a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="right-block">
            <img
              src="../../assets/images/homePage/logo.png"
              alt="logo"
              @click="download"
              style="cursor: pointer"
            />
          </div>
        </div>
      </div>
      <!-- 弹出框 -->
      <el-dialog
        :title="$t('footer.contact')"
        :visible.sync="messageDialog.visible"
        width="600px"
        :before-close="closeMessage"
      >
        <div class="message-wrap">
          <div class="message">
            {{ $t('footer.message') }}
          </div>
          <el-form
            :model="messageDialog.form"
            :rules="messageDialog.rules"
            ref="ruleForm"
            label-width="100px"
            class="ruleForm"
            label-position="top"
          >
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item :label="$t('footer.name')" prop="name">
                  <el-input
                    v-model="messageDialog.form.name"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('footer.corporate')" prop="companyName">
                  <el-input
                    v-model="messageDialog.form.companyName"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item :label="$t('footer.mobile')" prop="phone">
                  <el-input v-model="messageDialog.form.phone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('footer.email')" prop="email">
                  <el-input v-model="messageDialog.form.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item :label="$t('footer.consultation')" prop="content">
              <el-input
                v-model="messageDialog.form.content"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                maxlength="150"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item class="form-btn-group">
              <el-button @click="closeMessage" size="small">{{
                $t('common.cancel')
              }}</el-button>
              <el-button type="primary" @click="submitMessage" size="small">{{
                $t('common.determine')
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  export default {
    data() {
      var validatePhone = (rule, value, callback) => {
        const re = new RegExp(
          '^1(3\\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\\d|9[0-35-9])\\d{8}$'
        );
        if (value) {
          if (re.test(value)) {
            callback();
          } else {
            callback(new Error(this.$t('footer.validate')));
          }
        }
      };
      return {
        userInfo: null,
        messageDialog: {
          visible: false,
          form: {
            name: '',
            companyName: '',
            phone: '',
            email: '',
            content: ''
          },
          rules: {
            name: [
              {
                required: true,
                message: this.$t('footer.validate1'),
                trigger: 'change'
              }
            ],
            phone: [
              {
                required: true,
                message: this.$t('footer.validate2'),
                trigger: 'change'
              },
              { trigger: ['blur', 'change'], validator: validatePhone }
            ],
            email: [
              {
                required: true,
                message: this.$t('footer.validate3'),
                trigger: 'blur'
              },
              {
                type: 'email',
                message: this.$t('footer.validate4'),
                trigger: ['blur', 'change']
              }
            ]
          },
          loading: false
        }
      };
    },
    methods: {
      download() {
        window.open('https://www.tongyuan.cc/download#downloadTab-124', '_blank');
      },
      zhihu() {
        window.open('https://www.zhihu.com/org/tong-yuan-ruan-kong-75', '_blank');
      },
      bilibili() {
        window.open('https://space.bilibili.com/647146601/', '_blank');
      },
      // TODO: 需要替换为MoHub的文章
      weixin() {
        window.open(
          'https://mp.weixin.qq.com/s/l7ucQrbCDXerZEIoyfF0Mg',
          '_blank'
        );
      },
      openDoc(type) {
        this.$utils.toUrl('/doc?type=' + type);
      },
      openMessage() {
        this.messageDialog.visible = true;
      },
      closeMessage() {
        this.$refs.ruleForm.resetFields();
        this.messageDialog.visible = false;
      },
      submitMessage() {
        this.$refs.ruleForm.validate(async valid => {
          this.messageDialog.loading = true;
          if (valid) {
            const params = {
              userInputName: this.messageDialog.form.name,
              organization: this.messageDialog.form.companyName,
              phone: this.messageDialog.form.phone,
              email: this.messageDialog.form.email,
              content: this.messageDialog.form.content
            };
            // 发送请求
            const res = await this.$api.addFeedbackInfo(params);
            if (res.code === 0) {
              notify(this.$t('footer.send'), 'success');
            }
            this.messageDialog.loading = false;
            this.closeMessage();
          }
        });
      }
    },
    mounted() {
      this.userInfo = system.userInfo;
      this.$root.$on('onContactUs', () => {
        this.openMessage();
      });
    },
    destroy() {
      this.$root.$off('onContactUs');
    }
  };
  </script>
  <style lang="scss" scoped>
  .footer-panel-wrap {
    background: $--background-color-banner;
    min-width: 1250px;
    .content {
      width: 1250px;
      margin: 0 auto;
      .button-group {
        border-bottom: 1px solid #606060;
      }
    }
    .split-panel {
      display: flex;
      width: 1250px;
      margin: 0 auto;
      padding: 36px 0;
      .left-block {
        flex: 1;
        .info {
          font-size: 14px;
          font-weight: 400;
          height: 100%;
          line-height: 43px;
          color: #939393;
          display: flex;
          .beian-wrap {
            margin-left: 10px;
            display: flex;
            > a {
              margin-right: 8px;
            }
          }
        }
        .button-wrap {
          margin-top: 10px;
          .el-button {
            color: #ffffff;
          }
          .el-button:hover {
            color: $--color-primary;
          }
          // .el-button + .el-button {
          //   margin-left: 40px;
          // }
          .el-button {
            margin-left: 0;
            margin-right: 40px;
          }
        }
      }
      .right-block {
        .icon-wrap {
          color: $--color-white;
          opacity: 0.5;
          line-height: 37px;
          margin-top: 10px;
          i {
            cursor: pointer;
            font-size: 22px;
          }
          i + i {
            margin-left: 40px;
          }
          i:hover {
            color: $--color-primary;
          }
        }
      }
    }
  }
  .message-wrap {
    padding: 0 10px;
    .message {
      white-space: pre-wrap;
      color: #8c92a4;
    }
    /deep/.el-form {
      margin-top: 10px;
      .el-form-item__label {
        padding: 0;
        height: 30px;
        line-height: 30px;
      }
      .el-input__inner,
      .el-textarea__inner {
        border-radius: 4px;
      }
    }
  }
  .link {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: $--color-primary !important;
      p {
        color: inherit !important;
      }
    }
  }
  </style>
